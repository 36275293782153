import Head from 'next/head';
import Spinner from "./Spinner";
import {useGeneralStore} from "../stores/general";
import Header from "./inc/header";
import Footer from "./inc/footer";

export const siteTitle = 'Next.js Sample Website';

export default function Layout({ children }) {
	
	const showGlobalLoading = useGeneralStore((state) => state.showGlobalLoading)
	
	return (
		<>
			<Head>
				<meta charset="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			
			<div className="m-auto">
				<Header />
				
				<Spinner show={showGlobalLoading} full size={500} />
				
				<div className="md:w-3/5 lg:w-[36rem] mx-auto">
					{!showGlobalLoading &&
						children
					}
				</div>
				
				<Footer />
			</div>
		</>
	);
}
