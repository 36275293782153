import React, {useState} from 'react';
import Image from "next/image";
import Link from "next/link";
import {RxCross1, RxHamburgerMenu} from "react-icons/rx";

function Header(props) {
	
	const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
	
	function toggleMobileMenu() {
		setMobileMenuOpened(!mobileMenuOpened);
	}
	
	return (
		<>
			<header className="mx-auto container p-5 mb-10">
				<div className="flex justify-between items-center">
					<h1 className="m-0 d-inline-flex">
						<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/'}>
							<Image
								src="/images/pagename-logo-color-and-black.png"
								height={32}
								width={175}
								style={{alignSelf: 'center'}}
								alt="PageName Logo"
							/>
						</Link>
					</h1>
					<div className="uppercase text-sm font-bold hidden sm:block">
						<Link href={process.env.NEXT_PUBLIC_AUTH_URL + '/pricing'} className="inline-block">
							Pricing
						</Link>
						<Link href={process.env.NEXT_PUBLIC_AUTH_URL + '/login'} className="inline-block px-10">
							Login
						</Link>
						<Link href={process.env.NEXT_PUBLIC_AUTH_URL + '/signup'} className="inline-block btn-primary px-6 py-2 ">
							Sign Up
						</Link>
					</div>
					<div className="uppercase font-bold sm:hidden">
						<Link href={process.env.NEXT_PUBLIC_AUTH_URL + '/login'} className="inline-block">
							Login
						</Link>
					</div>
					
					<button
						className="header-nav-toggle sm:hidden"
						aria-controls="primary-menu"
						aria-expanded="true"
						onClick={() => toggleMobileMenu()}
					>
						<span className="sr-only">Menu</span>
						{!mobileMenuOpened ?
							<RxHamburgerMenu size={20} />
							:
							<RxCross1 size={20} />
						}
					</button>
				</div>
			</header>
			{mobileMenuOpened &&
				<ul className="absolute z-50 text-sm -mt-10 w-full uppercase text-center bg-[#1e2238] text-white py-5">
					<li className="py-4">
						<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/pricing'}>
							Pricing
						</Link>
					</li>
					<li className="py-4">
						<Link href={process.env.NEXT_PUBLIC_AUTH_URL + '/login'} className="login-link">
							Login
						</Link>
					</li>
					<li className="py-4">
						<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/pricing'} className="btn-primary py-2 px-20 button-wide-mobile button-sm">
							Sign up
						</Link>
					</li>
				</ul>
			}
		</>
	);
}

export default Header;

